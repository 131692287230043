import { Actions, IGetUserToken, IGetManagerToken, IGetAzAccessToken } from "../actions/AuthActions";
import { combineReducers } from "redux";

const getUserTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_VALID_USER_TOKEN_LOADING:
    case Actions.GET_VALID_USER_TOKEN_ERROR:
    case Actions.GET_VALID_USER_TOKEN_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

const getManagerTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_VALID_MANAGER_TOKEN_LOADING:
    case Actions.GET_VALID_MANAGER_TOKEN_ERROR:
    case Actions.GET_VALID_MANAGER_TOKEN_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

const getAzAccessTokenReducer = (state = {}, action: any) => {
    switch (action.type) {
    case Actions.GET_VALID_AZ_ACCESS_TOKEN_LOADING:
    case Actions.GET_VALID_AZ_ACCESS_TOKEN_ERROR:
    case Actions.GET_VALID_AZ_ACCESS_TOKEN_SUCCESS:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};

export interface IAuthData {
  userAuth: IGetUserToken;
  managerAuth: IGetManagerToken;
  azAccessAuth: IGetAzAccessToken;
}

const authData = combineReducers({
    userAuth: getUserTokenReducer,
    managerAuth: getManagerTokenReducer,
    azAccessAuth: getAzAccessTokenReducer,
});

export default authData;

import {
    errorAction,
    loadingAction,
    successSideEffectState,
    SideEffectSchema,
} from ".";

export const Actions = {
    //User Token
    GET_VALID_USER_TOKEN: "GET_VALID_USER_TOKEN",
    GET_VALID_USER_TOKEN_LOADING: "GET_VALID_USER_TOKEN_LOADING",
    GET_VALID_USER_TOKEN_ERROR: "GET_VALID_USER_TOKEN_ERROR",
    GET_VALID_USER_TOKEN_SUCCESS: "GET_VALID_USER_TOKEN_SUCCESS",

    //Mamager Token
    GET_VALID_MANAGER_TOKEN: "GET_VALID_MANAGER_TOKEN",
    GET_VALID_MANAGER_TOKEN_LOADING: "GET_VALID_MANAGER_TOKEN_LOADING",
    GET_VALID_MANAGER_TOKEN_ERROR: "GET_VALID_MANAGER_TOKEN_ERROR",
    GET_VALID_MANAGER_TOKEN_SUCCESS: "GET_VALID_MANAGER_TOKEN_SUCCESS",


    //Az Access Token
    GET_VALID_AZ_ACCESS_TOKEN: "GET_VALID_AZ_ACCESS_TOKEN",
    GET_VALID_AZ_ACCESS_TOKEN_LOADING: "GET_VALID_AZ_ACCESS_TOKEN_LOADING",
    GET_VALID_AZ_ACCESS_TOKEN_ERROR: "GET_VALID_AZ_ACCESS_TOKEN_ERROR",
    GET_VALID_AZ_ACCESS_TOKEN_SUCCESS: "GET_VALID_AZ_ACCESS_TOKEN_SUCCESS",

};

export interface IGetUserToken extends SideEffectSchema {
    status_code: string;
    message: string;
    istatus_code: string;
    token: string;
    comp_code: string;
}

export interface IGetManagerToken extends SideEffectSchema {
    status_code: string;
    message: string;
    istatus_code: string;
    token: string;
    comp_code: string;
}

export interface IGetAzAccessTokenPayLaod {
    scope: string;
    client_id: string;
    client_secret: string;
    grant_type: string;
    refresh_token: string;
}

export interface IGetAzAccessToken extends SideEffectSchema {
    token_type: string;
    scope: string;
    expires_in: string;
    ext_expires_in: string;
    access_token: string;
}

export const getUserTokenAction = (
    empId: any,
    username: string,
    token?: string,
) => {
    return {
        type: Actions.GET_VALID_USER_TOKEN,
        payload: { token, username, empId },
    };
};
export const getUserTokenLoadingAction = () =>
    loadingAction(Actions.GET_VALID_USER_TOKEN_LOADING);
export const getUserTokenErrorAction = (error: string) =>
    errorAction(Actions.GET_VALID_USER_TOKEN_ERROR, error);
export const getUserTokenSuccessAction = (data: any) => {
    const payload: IGetUserToken = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_VALID_USER_TOKEN_SUCCESS,
        payload: payload,
    };
};


export const getManagerTokenAction = (
    empId: any,
    username: string,
    token?: string,
) => {
    return {
        type: Actions.GET_VALID_MANAGER_TOKEN,
        payload: { token, username, empId },
    };
};
export const getManagerTokenLoadingAction = () =>
    loadingAction(Actions.GET_VALID_MANAGER_TOKEN_LOADING);
export const getManagerTokenErrorAction = (error: string) =>
    errorAction(Actions.GET_VALID_MANAGER_TOKEN_ERROR, error);
export const getManagerTokenSuccessAction = (data: any) => {
    const payload: IGetUserToken = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_VALID_MANAGER_TOKEN_SUCCESS,
        payload: payload,
    };
};


export const getAzAccessTokenAction = (
) => {
    return {
        type: Actions.GET_VALID_AZ_ACCESS_TOKEN,
    };
};
export const getAzAccessTokenLoadingAction = () =>
    loadingAction(Actions.GET_VALID_AZ_ACCESS_TOKEN_LOADING);
export const getAzAccessTokenErrorAction = (error: string) =>
    errorAction(Actions.GET_VALID_AZ_ACCESS_TOKEN_ERROR, error);
export const getAzAccessTokenSuccessAction = (
    data: IGetAzAccessToken
) => {
    const payload: IGetAzAccessToken = {
        ...data,
        ...successSideEffectState,
    };
    return {
        type: Actions.GET_VALID_AZ_ACCESS_TOKEN_SUCCESS,
        payload: payload,
    };
};
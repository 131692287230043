export const API_BASE_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_API_URL;
export const API_SECRET_TOKEN = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VC_API_SECRET : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VC_API_SECRET : process.env.REACT_APP_VC_API_SECRET;
export const API_VACCINE_URL = (process.env.REACT_APP_ENV === "production") ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL;
export const USER_TOKEN_API = "/api/authentication/get_subsidiary_token/";
export const CMF_BASE_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_CMF_BASE_URL : process.env.REACT_APP_CMF_BASE_URL;
export const ITSM_BASE_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_ITSM_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_ITSM_URL : process.env.REACT_APP_ITSM_URL;
export const VC_BASE_URL = API_VACCINE_URL;
export const MTM_IMAGE_BASE_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_MTM_IMAGE_URL : process.env.REACT_APP_MTM_IMAGE_URL;
export const SAP_ID_URL = process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_SAP_ID_URL : API_VACCINE_URL;
export const TATA_SHAREPOINT_BASE_URL = "https://tatamotors.sharepoint.com";

export const ATTENDANCE_API_CONFIG = {
    GET_ATTENDANCE_ENQUIRY: "/leave/leave_requests/get_attendance_enquiry/",
};

export const USER_INFO_API_CONFIG = {
    GET_USER_MANAGER_INFO: "/authentication/get_user_and_manager_details/",
    GET_USER_INFO: "/authentication/get_edp_employee_details/",
    GET_TEAM_MEMBERS: "/success_factor/get_success_factor_user_details/",
};

export const VACCINE_API_CONFIG = {
    GET_VACCINE: "/api/vaccine/get-vaccine/" || "/api/get-vaccine-by-id",
    POST_VACCINE: "/api/vaccine/post-vaccine/" || "/api/submit-vaccine-data",
    PUT_VACCINE: "/api/vaccine/put-vaccine/" || "/api/put-vaccine"
};

export const ANNOUNCEMENT_API_CONFIG = {
    GET_ANNOUNCEMENT_LIST: "/admin/announcements/get_announcement_notification/",
    GET_ANNOUNCEMENT_FEEDBACK_LIST: "/admin/announcements/get_announcement_employee_record/",
    POST_ANNOUNCEMENT_FEEDBACK_LIST: "/admin/announcements/update_announcement_user_response/",
};

export const LEAVE_API_CONFIG = {
    GET_LEAVE_QUOTA: "/leave/leave_requests/get_leave_quota/",
    GET_LEAVE_REQUESTS_MASTER: "/leave/leave_requests/get_leave_requests_master/",
    GET_LEAVE_REQUESTS: "/leave/leave_requests/get_leave_requests/",
    GET_LEAVE_REQUESTS_APPROVAL: "/leave/leave_requests/get_leave_requests_approval/",
    VALIDATE_LEAVE_REQUEST: "/leave/leave_requests/validate_leave_request/",
    APPLY_LEAVE_REQUEST: "/leave/leave_requests/create_leave_request_edp/",
    DELETE_LEAVE_REQUEST: "/leave/leave_requests/delete_leave_request/",
    LEAVE_REQUEST_APPROVAL: "/leave/leave_requests/leave_request_approval/",
    DELETE_LEAVE_REQUEST_APPROVAL: "/leave/leave_requests/delete_leave_request_approval/",
    GET_UPCOMING_HOLIDAY_LIST: "/authentication/get_current_year_holiday_list/",
    GET_ACTIVE_YEAR: "/leave/leave_requests/get_active_year/"
};

export const TODO_API_CONFIG = {
    GET_TODO_TASK: "/ms_todo/get_all_tasks/",
    CREATE_LIST: "/ms_todo/create_list/",
    CREATE_TASK: "/ms_todo/create_task/",
    UPDATE_TASK: "/ms_todo/update_task/",
    DELETE_TASK: "/ms_todo/delete_task/",
    GET_TODO_LIST: "/ms_todo/get_todolist_details/",
    GET_TODO_MASTER_DATA: "/ms_todo/get_ms_todo_master_data/"
};

export const CFM_API_CONFIG = {
    GET_SUCCESS_FACTOR_USER_DETAILS: "/success_factor/get_success_factor_user_details/",
    GET_EMPLOYEE_GOALS: "/success_factor/get_employee_goals/",
    ADD_GOAL_COMMENTS: "/success_factor/add_goal_comments/",
    GET_EMPLOYEE_COMPETENCIES: "/success_factor/get_employee_competencies/",
    ADD_COMPETENCIES_COMMENT: "/success_factor/add_competencies_comments/",
    GET_GOAL_COMMENTS: "/success_factor/get_goal_comments/",
    GET_COMPETENCY_COMMENTS: "/success_factor/get_competency_comments/",
    GET_PERFORMANCE_TIMELINE: "/success_factor/get_performance_timeline/",
    SEND_FEEDBACK_REQUEST: "/success_factor/send_feedback_request/",
    GET_LEADERSHIP_BEHAVIOUR_DESCRIPTION: "/success_factor/get_leadership_behaviour_description/",
    UPDATE_MANAGER_GOAL_COMMENT: "/success_factor/update_manager_goal_comment/",
    UPDATE_MANAGER_COMPETENCY_COMMENT: "/success_factor/update_manager_competency_comment/",
    DELETE_MANAGER_GOAL_COMMENT: "/success_factor/delete_manager_goal_comment/",
    DELETE_MANAGER_COMPETENCY_COMMENT: "/success_factor/delete_manager_competency_comment/"
};

export const NOTIFICATION_API_CONIG = {
    GET_NOTIFICATION: "/profile/attendance_marking/get_notifications/"
};

export const DOCUMENT_API_CONFIG = {
    GET_CURRENT_MASTER_LIST: "/documents/document/get_master_dropdown_for_document_center_v1/",
    GET_CURRENT_PAYSLIP_WORKSHEET_LIST: "/documents/document/get_payslips_and_itworksheet_v1/",
    GET_FORM_SIXTEEN: "/documents/document/get_form16_documents_v1/",
    GET_D0WNLOAD_DOCUMENT_LIST: "/documents/document/get_document_v1/",
    GET_PREVIOUS_MASTER_LIST: "/documents/document/master_data_for_old_documents_monthly/",
    GET_PREVIOUS_PAYSLIP_WORKSHEET_LIST: "/documents/document/get_all_old_documents_monthly/",
    GET_ALL_OLD_DOC_LIST: "/documents/document/get_all_old_documents_annual/",
    GET_PREVIOUS_DOWNLOAD_LIST: "/documents/document/get_old_document/",
    GET_EMPLOYEE_LATEST_LIST: "/documents/document/get_available_latest_documents_v1/",
    GET_PASSBOOK_DETAILS_LIST: "/documents/document/get_passbook_details/",
    GET_TRUSTFUND_LIST: "/documents/document/get_annual_statement/",
    GET_EMPLOYEE_APPLICABLE_LIST: "/documents/document/employee_applicable_annual_statement/",
    GET_GENERATE_BONAFIDE_API: "/documents/document/get_bonafide_details/",
    GET_EMPLOYEE_ADDITIONAL_LIST: "/documents/document/get_employee_additional_documents/",
    GET_DOWNLOAD_ADDITIONAL: "/documents/document/download_additional_documents/"
};

export const ITSM_API_CONFIG = {
    GET_TOKEN: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/tatamotorsrestapi/api/clients/token`,
    GET_TICKETS: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/GetTickets/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/GetTickets/" + process.env.REACT_APP_ENV}`,
    ADD_NOTES: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/AddNotes/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/AddNotes/" + process.env.REACT_APP_ENV}`,
    CREATE_TICKET: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/CreateTicket/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/CreateTicket/" + process.env.REACT_APP_ENV}`,
    UPDATE_TICKET: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/UpdateTicket/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/UpdateTicket/" + process.env.REACT_APP_ENV}`,
    ADD_ATTACHMENT: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/AddAttachment/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/AddAttachment/" + process.env.REACT_APP_ENV}`,
    GET_USER_APPROVAL_LIST: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/GetMyApprovals/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/GetMyApprovals/" + process.env.REACT_APP_ENV}`,
    UPDATE_APPROVAL: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/UpdateApproval/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/UpdateApproval/" + process.env.REACT_APP_ENV}`,
    GET_TICKET_CATALOG: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/CreateTicketForCatalog/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/CreateTicketForCatalog/" + process.env.REACT_APP_ENV}`,
    GET_TICKET_APPROVAL: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/GetTicketApprovals/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/GetTicketApprovals/" + process.env.REACT_APP_ENV}`,
    GET_CATEGORY_LIST: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/itsm/get-category`,
    GET_SUB_CATEGORY_LIST: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/itsm/get-sub-category`,
    GET_ITEMS_LIST: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/itsm/get-item`,
};

export const ITTIPS_API_CONFIG = {
    GET_ITTIPS_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/sql/getITtipsData`
};

export const IMAC_API_CONFIG = {
    GET_FLOOR_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/imac/get-floor`,
    GET_LOCATION_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/imac/get-location`,
    GET_SITE_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/imac/get-site`,
    GET_IMAC_CATEGORY_ONE_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/imac/get-category-one`,
    GET_IMAC_CATEGORY_TWO_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/imac/get-category-two`,
    GET_IMAC_CATEGORY_THREE_DATA: `${process.env.REACT_APP_ENV === "production" ? process.env.REACT_APP_PROD_VACCINATION_API_URL : process.env.REACT_APP_ENV === "stag" ? process.env.REACT_APP_STAG_VACCINATION_API_URL : process.env.REACT_APP_VACCINATION_API_URL}/api/imac/get-category-three`,
    GET_ASSETS_API: `${process.env.REACT_APP_ENV === "production" ? "/tatamotorsrestapi/api/clients/getAssets/" + process.env.REACT_APP_ENV : "/tatamotorsrestapi/api/clients/getAssets/" + process.env.REACT_APP_ENV}`,
    POST_DOMIAN_ID_BY_AD: "https://graph.microsoft.com/v1.0/search/query",
    // POST_DOMIAN_ID_BY_AD: "https://graph.microsoft.com/v1.0/me/people/?$search=",
    POST_DOMIAN_ID_MANAGER_BY_AD: "https://graph.microsoft.com/v1.0/users/"
};

export const SERVICE_CONFIG = {
    GET_SERVICE_AVAILABILITY: "/documents/cet_syncing/get_service_availability/"
};

export const ITSM_NOTIFICATION_CONFIG = {
    POST_ITSM_NOTIFICATION: `${API_VACCINE_URL}/api/notification/send-notification`
};

export const VISITOR_CONFIG = {
    GET_MASTER_LOCATION_DATA: `/vc/visiting-card/get-master-location/${process.env.REACT_APP_ENV}`,
    GET_MASTER_FIELD_NAMES_DATA: `/vc/visiting-card/get-master-field-names/${process.env.REACT_APP_ENV}`,
    GET_OFFICE_LOCATION_DATA: `/vc/visiting-card/get-office-location/${process.env.REACT_APP_ENV}`,
    GET_DISPLAY_FIELD_DATA: `/vc/visiting-card/display-fields/${process.env.REACT_APP_ENV}`,
    GET_ALL_STATUS: `/vc/visiting-card/get-all-status/${process.env.REACT_APP_ENV}`,
    GET_SAVE_DIGITAL: `/vc/visiting-card/save-digital-visiting-card/${process.env.REACT_APP_ENV}`,
    GET_SAVE_PHYSICAL: `/vc/visiting-card/save-physical-visiting-card/${process.env.REACT_APP_ENV}`,
    GET_CARD_QUANTITY: `/vc/visiting-card/get-card-quantity/${process.env.REACT_APP_ENV}`,
    GET_SYSTEM_SHUTDOWN: `/vc/visiting-card/get-system-shut-down-date/${process.env.REACT_APP_ENV}`,
    GET_DIGITAL_VC: `/vc/visiting-card/get-teams-digital-visiting-card/${process.env.REACT_APP_ENV}`,
    GET_PHYSICAL_VC: `/vc/visiting-card/get-teams-physical-visiting-card/${process.env.REACT_APP_ENV}`,
    GET_QR_STRING: `/vc/visiting-card/qr-code-string-generator/${process.env.REACT_APP_ENV}`,
    UPDATE_PHYSICAL: `/vc/visiting-card/update-physical-visiting-card/${process.env.REACT_APP_ENV}`
};

export const PIN_CONFIG = {
    GET_PIN_DATA: "/api/pin/get-dashboard-pin-sidebar",
    CREATE_OR_UPDATE_PIN_DATA: "/api/pin/update-dashboard-pin-sidebar"
};

export const MTM_CONFIG = {
    GET_MOMENT_FEED_DATA: "/moments_that_matter/get_moment_feed_data/",
    UPDATE_USER_REACTION_DATA: "/moments_that_matter/update_moment_user_reactions_response/",
    GET_EMPLOYEE_REACTION_DATA: "/moments_that_matter/get_moment_employee_reaction_response/",
    GET_MOOMENT_MATTER_DATA: "/moments_that_matter/is_moment_that_matter_present/",
    GET_SEND_WISH_NOTIFICATION_DATA: "/moments_that_matter/send_wish_email_notification/",
    GET_MY_MILESTONES_DATA: "/moments_that_matter/get_milestone/",
    GET_UPCOMING_MILESTONES_DATA: "/moments_that_matter/get_upcoming_milestone/",
};

export const CHANNEL_CONFIG = {
    GET_CHANNEL_DATA: "/api/channel/get-channel",
    GET_VIDEO_LIBRARY: "/sites/C-CorpHR/s-drishtivideo/_api/web/lists/GetByTitle(%27VideoLibrary%27)/items?$expand=File&$select=File/Name,File/ServerRelativeUrl,Title,VideoDescription,Date,Category"
};


export const SENDMAIL_CONFIG = {
    GET_SEND_MAIL_DATA: "/api/mail/send-mail",
};

export const SAP_ID_CONFIG = {
    GET_USER_DETAILS_DATA: "/sap/bc/zrest_user/USER",
    GET_USER_ROLES_ASSIGN: "/sap/bc/zrest_user_role/USERROLES",
    GET_USER_SYSTEM_DETAILS: "/sap/bc/zrest_user_syst/USERSYSTEM",
    GET_SYSTEM_DESCRIPTION: "/sap/bc/zrest_syst_desc/SYSTEM_DESC",
    GET_REQ_USER_DETAILS: "/sap/bc/zrest_req_user/REQUSER",
    GET_NO_SYSTEM_ASSIGNED_USER: "/sap/bc/zrest_sys_count/SYSTEMCOUNT",
    POST_USER_CREATE: "/sap/bc/zrest_new_user/USER_CREATE",
    GET_APPROVER_INBOX: "/sap/bc/zrest_appr_in/APPR_INBOX",
    GET_FINDING_T_CODES_ROLE: "/sap/bc/zrest_tcode_rol/TCODEROLE",
    GET_FINDING_ORG_VALUES_ROLE: "/sap/bc/zrest_org_role/ORGROLE",
    GET_ROLE_OWNER_ROLE_NAME: "/sap/bc/zrest_rowner/roleowner",
    GET_LIST_BUSINESS_PROCESS: "/sap/bc/zrest_bprocess/bprocess",
    GET_LIST_ROLE_ASSIGNED_TO_OWNER: "/sap/bc/zrest_lroles/lroles",
    POST_API_FOR_APPROVE_REQ: "/sap/bc/zrest_apprreq/APPR_REQ",
    GET_SYSTEM_TYPE: "",
    GET_FUNCTION_DATA: "/sap/sap-id/get-function-list",
    GET_SUBFUNCTION_DATA: "/sap/sap-id/get-sub-function-list",
    GET_DEPARTMENT_DATA: "/sap/sap-id/get-department-list",
    GET_SUBDEPARTMENT_DATA: "/sap/sap-id/get-sub-department-list",
    GET_ROLE_OWNER_DATA: "/sap/sap-id/get-role-list",
    GET_ROLE_DATA: "/sap/sap-id/get-role-owner-list",
    POST_SOD_CONFLICT: "/sap/bc/zrest_sod_01/SOD_01",
};

export const ACR_SAP_CONFIG = {
    GET_ACR_SAP_APPROVAL_LIST: "/sap-acr-api/get-sap-acr-list",
    GET_ACR_SAP_REQUEST_DETAILS: "/sap-acr-api/get-sap-acr-details",
    GET_ACR_SAP_REQUEST_INITIATOR: "/sap-acr-api/get-sap-acr-initiator-list",
    GET_ACR_SAP_EMP_ROW_COUNT: "/sap-acr-api/count-emp-row",
    POST_ACR_SAP_REQUEST_UPDATE: "/sap-acr-api/update-sap-acr-details",

};

export const COMPENSATION_CONFIG = {
    GET_COMPENSATION_STRUCTURE_INSTRUCTION: "/payspace/choice_pay/get_compensation_structure_instructions/",
    GET_COMPENSATION_STRUCTURE: "/payspace/choice_pay/get_compensation_structure/",
    GET_COMPENSATION_STRUCTURE_PDF: "/payspace/choice_pay/get_compensation_structure_pdf/"
};